import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import {
  ScreenRoot,
  FormContainer,
  SideContainer,
  LargeText,
  StyledSpan,
} from "./styles";
import { getOnboarding } from "../../api/onboarding";
import { QualificationSection } from "./qualificationSection";
import { FunctionSection } from "./functionSection";
import { SideSection } from "./sideSection";
import { useSelector } from "react-redux";
import { contactSelector } from "../../data/selectors/contact";

function ContractorDashboard() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [userData, setUserData] = useState("");
  const contactData = useSelector(contactSelector);
  let history = useHistory();

  useEffect(() => {
    getOnboarding(
      (data) => {
        if (data.status != "active") {
          history.push("/onboarding");
        } else {
          setUserData(data);
        }
      },
      () => {},
    );
  }, []);

  return (
    <ScreenRoot isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <LargeText isMobile={isMobile}>
          Welcome Back,{" "}
          <StyledSpan>
            {contactData?.first_name
              ? contactData?.first_name
              : contactData?.last_name}
          </StyledSpan>
          !
        </LargeText>
        <QualificationSection isMobile={isMobile} userData={userData} />
        <FunctionSection isMobile={isMobile} userData={userData} />
      </FormContainer>
      {!isMobile && (
        <SideContainer isMobile={isMobile}>
          <SideSection isMobile={isMobile} />
        </SideContainer>
      )}
    </ScreenRoot>
  );
}

export default ContractorDashboard;
