import styled from "styled-components";

export const StyledToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 14px 16px;
  border-radius: 0 0 16px 16px;
`;

export const StyledActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
