import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  DeleteButton,
  FunctionButtonContainer,
  HorizontalContainer,
  ScreenRoot,
  StyledHeader,
  StyledTable,
  StyledTd,
  TableContainer,
  VerticalContainer,
} from "../styles";
import {
  FileUploadSection,
  FlexContainer,
  ProceedButtonContainer,
  SearchBarContainer,
  SearchUploadContainer,
  StageContainer,
  StageItem,
  StageLine,
  StageNumber,
  StyledTr,
  TitleContainer,
  UploadButton,
  UploadContainer,
  UploadTextHeader,
  UploadTextSubheader,
} from "./styles";
import { updateUnderwriterIdpDocumentCounter } from "api/external_data.js";
import { LargeText, MediumText } from "../../styles";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import {
  getUnderwriterClientInfo,
  getUnderwriterIdpActiveDocuments,
} from "../../../../data/actions/underwriters";
import { useDispatch, useSelector } from "react-redux";
import {
  underwriterClientSelector,
  underwriterIdpActiveDocumentsSelector,
} from "../../../../data/selectors/underwriters";
import { UploadIcon } from "../../../../assets/common/UploadIcon";
import axios from "axios";
import { API_HOST } from "../../../../constants";
import { AlertPopup } from "../../../../components/alertPopup/alertPopup";
import { FunctionalButton } from "../../../../components/functionalButton";
import { useHistory, useLocation } from "react-router";
import { ReactComponent as DeleteIcon } from "../../../../assets/common/deleteIcon.svg";
import { pdfjs } from "react-pdf";
import worker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = worker;

/**
 * Uploader is a stage in the **IDP Workflow**, it allows users to upload PDF files for an underwriter account.
 * Users can select an account, upload up to 3 files, and proceed to the next step in the process.
 *
 * @component
 * @returns {JSX.Element} The Uploader component.
 *
 * @example
 * return (
 *   <Uploader />
 * )
 */
export default function Uploader() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  const history = useHistory();

  const MAX_FILES_COUNT = 1;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAutomatedFlow = queryParams.get("automated") === "true";

  const fileRef = useRef();

  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [files, setFiles] = useState([]);
  const [showFileLimitPopup, setShowFileLimitPopup] = useState(false);
  const [showRemoveSelectedFilesPopup, setShowRemoveSelectedFilesPopup] =
    useState(false);
  const [showQuotaMetPopup, setShowQuotaMetPopup] = useState(false);

  const [clientDocumentsId, setClientDocumentsId] = useState(0);
  const [bucketName, setBucketName] = useState("");
  const [filePath, setFilePath] = useState("");

  const [activeDocuments, setActiveDocuments] = useState([]);

  const [
    showPasswordProtectedDocumentPopup,
    setShowPasswordProtectedDocumentPopup,
  ] = useState(false);

  const [loading, setLoading] = useState(false);

  const [showPendingDocumentPopup, setShowPendingDocumentPopup] =
    useState(false);

  const [selectedAccount, setSelectedAccount] = useState({
    AccountId: "",
    CompanyName: "",
    ClassifierThreshold: 0,
    documentsCount: 0,
  });

  const underwriterClientInfoData = useSelector(
    underwriterClientSelector,
    (prev, next) => prev.length === next.length,
  );

  const underwriterIdpActiveDocumentsData = useSelector(
    underwriterIdpActiveDocumentsSelector,
    (prev, next) => prev.length === next.length,
  );

  useEffect(() => {
    if (underwriterClientInfoData.length < 1) {
      dispatch(getUnderwriterClientInfo());
    } else {
      setFilteredData(underwriterClientInfoData);
    }
    if (Array.isArray(underwriterIdpActiveDocumentsData)) {
      underwriterIdpActiveDocumentsData?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      setActiveDocuments(underwriterIdpActiveDocumentsData);
    } else {
      setActiveDocuments([]);
    }
  }, [underwriterClientInfoData, underwriterIdpActiveDocumentsData]);

  const handleOpenFile = () => {
    fileRef.current.click();
  };

  const handleFileChange = (event) => {
    const uploadedFiles = event.target.files;

    if (uploadedFiles.length > MAX_FILES_COUNT) {
      setShowFileLimitPopup(true);
      event.target.value = "";
    } else {
      const file = uploadedFiles[0];

      const checkIfPDFHasPassword = async (file) => {
        try {
          const fileText = await file.text();
          return fileText.includes("/Encrypt");
        } catch (error) {
          console.error("Error checking for PDF password:", error);
          return null;
        }
      };

      checkIfPDFHasPassword(file).then((isPasswordProtected) => {
        if (isPasswordProtected) {
          console.error("PDF is password protected");
          setShowPasswordProtectedDocumentPopup(true);
          // console.log("File is password-protected, rasterizing...");
          // rasterizePDF(file).then((rasterizedBlob) => {
          //   if (rasterizedBlob) {
          //     const fileWithMeta = {
          //       blob: rasterizedBlob,
          //       name: file.name,
          //       size: file.size,
          //       type: rasterizedBlob.type,
          //     };
          //
          //     console.log(
          //       "Rasterization complete, adding to files state...",
          //       fileWithMeta,
          //     );
          //
          //     setFiles((files) =>
          //       files.length === 0 ? [fileWithMeta] : [...files, fileWithMeta],
          //     );
          //   } else {
          //     console.error("Rasterization failed.");
          //   }
          // });
        } else {
          console.log(
            "File is not password-protected, adding directly to files.",
          );
          setFiles((files) => (files.length === 0 ? [file] : [...files, file]));
        }
      });
    }
  };

  const handleUploadPdfFiles = async (files, options = {}) => {
    const { isAutomatedFlow } = options;
    setLoading(true);
    let headers = {};
    const getAuth = () => {
      const authData = localStorage.getItem("Authorization");
      if (authData) {
        headers = { Authorization: authData };
      }
    };
    //setShowFileLimitPopup(true);
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append("account_id", selectedAccount.AccountId);
      formData.append("company_name", selectedAccount.CompanyName);

      if (isAutomatedFlow) {
        formData.append("automatic", "Auto");
      }

      files.forEach((file) => {
        formData.append("pdfFile[]", file);
      });
      try {
        getAuth();

        // This API will respond with client_documents_id
        const response = await axios.post(
          `${API_HOST}/underwriter/idp/upload/files`,
          formData,
          {
            headers: {
              ...headers,
              "Content-Type": "multipart/form-data",
            },
          },
        );

        await updateUnderwriterIdpDocumentCounter(
          {},
          () => {
            console.log("Successfully updated the counter");
          },
          (error) => {
            console.log("Error updated counter: ", error);
          },
        );

        const { clientDocumentsId, bucketName, filePath } = response.data;

        setClientDocumentsId(clientDocumentsId);
        setBucketName(bucketName);
        setFilePath(filePath);

        return { clientDocumentsId, bucketName, filePath };
      } catch (error) {
        console.log("there was an error uploading the files ");
      } finally {
        setLoading(false);
      }
    }
  };

  console.log(
    "3 values after file upload ",
    clientDocumentsId,
    bucketName,
    filePath,
  );

  const handleProceed = async () => {
    if (!isAutomatedFlow) {
      const { clientDocumentsId, bucketName, filePath } =
        await handleUploadPdfFiles(files);

      console.log("state being passed to splitter ", {
        selectedAccount,
        clientDocumentsId,
        bucketName,
        filePath,
      });

      history.push({
        pathname: "/underwriter/idpdocuments/splitter",
        state: { selectedAccount, clientDocumentsId, bucketName, filePath },
      });
    } else {
      const { clientDocumentsId, bucketName, filePath } =
        await handleUploadPdfFiles(files, { isAutomatedFlow: true });

      console.log("state being passed to splitter ", {
        selectedAccount,
        clientDocumentsId,
        bucketName,
        filePath,
      });

      history.push({
        pathname: "/underwriter/idpdocuments?automated=true",
        state: {
          selectedAccount,
          clientDocumentsId,
          bucketName,
          filePath,
        },
      });
    }
  };

  return (
    <>
      <AlertPopup
        headerText="Password Protected Document"
        height="434px"
        subHeaderText="The uploaded document is password protected. To proceed, please unlock it and try again."
        openPopup={showPasswordProtectedDocumentPopup}
        setOpenPopup={setShowPasswordProtectedDocumentPopup}
        showIcon={true}
        width={"640px"}
        showButton={false}
      />
      <AlertPopup
        headerText="IDP Pending For Client"
        height="434px"
        subHeaderText="Please remove pending document."
        openPopup={showPendingDocumentPopup}
        setOpenPopup={setShowPendingDocumentPopup}
        showIcon={true}
        width={"640px"}
        showButton={false}
      />
      <AlertPopup
        headerText="Quota Reached"
        height="434px"
        subHeaderText="You've reached the IDP quota limit for this account. To continue processing documents, please contact support."
        openPopup={showQuotaMetPopup}
        setOpenPopup={setShowQuotaMetPopup}
        showIcon={true}
        width={"640px"}
        showButton={false}
      />
      <AlertPopup
        headerText="Remove Uploaded Document"
        height="434px"
        subHeaderText="Please remove uploaded document before selecting a new client."
        openPopup={showRemoveSelectedFilesPopup}
        setOpenPopup={setShowRemoveSelectedFilesPopup}
        showIcon={true}
        width={"640px"}
        showButton={false}
      />
      <AlertPopup
        headerText="Too many files selected"
        height="434px"
        subHeaderText={`Too many files selected. Maximum is ${MAX_FILES_COUNT}.`}
        openPopup={showFileLimitPopup}
        setOpenPopup={setShowFileLimitPopup}
        showIcon={true}
        width={"640px"}
        showButton={false}
      />
      <ScreenRoot isMobile={isMobile}>
        <VerticalContainer>
          <HorizontalContainer>
            <TitleContainer>
              <LargeText>Document Uploader</LargeText>
              <MediumText>Select the account for this document</MediumText>
            </TitleContainer>
            {!isAutomatedFlow ? (
              <StageList
                stages={["Uploader", "Splitter", "Classifier", "Parser"]}
                currentStage="Uploader"
              />
            ) : (
              <StageList stages={["Uploader"]} currentStage="Uploader" />
            )}
            <ProceedButtonContainer>
              <FunctionalButton
                buttonTitle={loading ? "Uploading..." : "Proceed"}
                handleButton={handleProceed}
                width="100%"
                height="48px"
                isReversedColor={false}
                marginRight={"48px"}
                marginLeft={"48px"}
                marginTop={"48px"}
                marginBottom={"48px"}
                fontSize={"16px"}
                disabled={files.length === 0 || loading}
                icon={null}
                hasBoxShadow={true}
                frontIcon={null}
                weight={600}
              />
            </ProceedButtonContainer>
          </HorizontalContainer>
          <SearchUploadContainer>
            <HorizontalContainer>
              <VerticalContainer>
                <SearchBarContainer>
                  <MediumText>Contractor Clients</MediumText>
                  <SearchBar
                    filteredData={underwriterClientInfoData}
                    setInternalSearchResults={setFilteredData}
                    query={query}
                    setQuery={setQuery}
                    internalSearch={true}
                    searchWidth="100%"
                    searchPlaceHolder="Search by company name or Account ID"
                  />
                </SearchBarContainer>
                <TableContainer>
                  <StyledTable>
                    <thead>
                      <tr>
                        <StyledHeader>Account ID</StyledHeader>
                        <StyledHeader>Company Name</StyledHeader>
                        <StyledHeader>Pending Documents</StyledHeader>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.length > 0 ? (
                        filteredData.map(
                          ({
                            AccountId,
                            CompanyName,
                            ClassifierThreshold,
                            count,
                            idp_doc_count,
                          }) => (
                            <StyledTr
                              key={AccountId}
                              selected={AccountId === selectedAccount.AccountId}
                              onClick={() => {
                                if (files.length !== 0) {
                                  setShowRemoveSelectedFilesPopup(true);
                                  return;
                                }

                                if (count >= idp_doc_count) {
                                  setShowQuotaMetPopup(true);
                                  return;
                                }

                                if (
                                  activeDocuments
                                    .map((doc) => doc.account_id)
                                    .includes(AccountId)
                                ) {
                                  setShowPendingDocumentPopup(true);
                                  return;
                                }

                                AccountId === selectedAccount.AccountId
                                  ? setSelectedAccount({
                                      AccountId: "",
                                      CompanyName: "",
                                      ClassifierThreshold: "",
                                      documentsCount: 0,
                                    })
                                  : setSelectedAccount({
                                      AccountId,
                                      CompanyName,
                                      ClassifierThreshold,
                                      documentsCount: count,
                                    });
                              }}
                            >
                              <StyledTd> {AccountId}</StyledTd>
                              <StyledTd>{CompanyName}</StyledTd>
                              <StyledTd>
                                {activeDocuments
                                  .map((doc) => doc.account_id)
                                  .includes(AccountId)
                                  ? "Yes"
                                  : ""}
                              </StyledTd>
                            </StyledTr>
                          ),
                        )
                      ) : (
                        <tr>
                          <td colSpan="2">Loading...</td>
                        </tr>
                      )}
                    </tbody>
                  </StyledTable>
                </TableContainer>
              </VerticalContainer>
              <FileUploadSection>
                <UploadContainer
                  disabled={
                    (!selectedAccount.AccountId &&
                      !selectedAccount.CompanyName) ||
                    files.length === 3 ||
                    activeDocuments
                      .map((doc) => doc.account_id)
                      .includes(selectedAccount.AccountId)
                  }
                >
                  <UploadTextHeader>Upload Documents</UploadTextHeader>
                  <UploadTextSubheader>
                    Maximum {MAX_FILES_COUNT} file, 20MB (.PDF .TIFF .GIF .TIF)
                  </UploadTextSubheader>
                  <UploadButton
                    disabled={
                      (!selectedAccount.AccountId &&
                        !selectedAccount.CompanyName) ||
                      files.length === 3
                    }
                    onClick={handleOpenFile}
                  >
                    <UploadIcon
                      color={
                        (!selectedAccount.AccountId &&
                          !selectedAccount.CompanyName) ||
                        files.length === 3
                          ? "grey"
                          : "#ff6d1d"
                      }
                    />
                    Choose File
                  </UploadButton>
                  <input
                    type="file"
                    ref={fileRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept=".pdf,.gif,.tiff,.tif"
                    multiple
                  />
                </UploadContainer>
                {files.length > 0 && (
                  <StyledTable>
                    <thead>
                      <tr>
                        <StyledHeader>Title</StyledHeader>
                        <StyledHeader>Size</StyledHeader>
                      </tr>
                    </thead>
                    <tbody>
                      {files.length > 0 &&
                        files.map((file) => (
                          <StyledTr key={file.name}>
                            <StyledTd>{file.name}</StyledTd>
                            <StyledTd>
                              {(file.size / (1024 * 1024)).toFixed(2)} MB
                            </StyledTd>
                            <FunctionButtonContainer>
                              <DeleteButton
                                // remove file from filelist
                                onClick={() => {
                                  const index = files.findIndex(
                                    (f) => f.name === file.name,
                                  );

                                  if (index !== -1) {
                                    const updatedFiles = [...files];
                                    updatedFiles.splice(index, 1);
                                    setFiles(updatedFiles);
                                    fileRef.current.value = "";
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </DeleteButton>
                            </FunctionButtonContainer>
                          </StyledTr>
                        ))}
                    </tbody>
                  </StyledTable>
                )}
              </FileUploadSection>
            </HorizontalContainer>
          </SearchUploadContainer>
        </VerticalContainer>
      </ScreenRoot>
    </>
  );
}

const CheckIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.49373 0.729138C8.83831 0.384558 9.39698 0.384558 9.74156 0.729138C10.0861 1.07372 10.0861 1.63239 9.74156 1.97697L4.44745 7.27109C4.10287 7.61567 3.54419 7.61567 3.19961 7.27109L0.258435 4.32992C-0.0861451 3.98533 -0.0861451 3.42666 0.258435 3.08208C0.603015 2.7375 1.16169 2.7375 1.50627 3.08208L3.82353 5.39934L8.49373 0.729138Z"
      fill="white"
    />
  </svg>
);

/**
 * Takes an array of stages (array of strings) and a current stage (string) and creates a
 wizard UI using them.
 *
 * @param {string[]} props.stages - An array of stage names.
 * @param {string} props.currentStage - The current stage name.
 * @returns {JSX.Element}
 * @throws Error Will throw an error if the currentStage is not included in the stages
 array.
 */
export const StageList = ({ stages, currentStage }) => {
  if (!stages.includes(currentStage)) {
    throw new Error("currentStage must be included in stages");
  }

  return (
    <FlexContainer>
      {stages.map((stage) => (
        <StageContainer key={stage} class="dd">
          <HorizontalContainer>
            <StageLine
              background={
                stages.indexOf(stage) <= stages.indexOf(currentStage)
                  ? "#FF6D1D"
                  : "#DCDCE1"
              }
            />
            <StageItem
              background={
                stages.indexOf(stage) <= stages.indexOf(currentStage)
                  ? "#FF6D1D"
                  : "#DCDCE1"
              }
            >
              {stage === currentStage ? (
                <StageNumber>{stages.indexOf(stage) + 1}</StageNumber>
              ) : stages.indexOf(stage) < stages.indexOf(currentStage) ? (
                <CheckIcon />
              ) : (
                <StageNumber>{stages.indexOf(stage) + 1}</StageNumber>
              )}
            </StageItem>
            <StageLine
              background={
                stages.indexOf(stage) < stages.indexOf(currentStage)
                  ? "#FF6D1D"
                  : "#DCDCE1"
              }
            />
          </HorizontalContainer>
          <p style={{ textAlign: "center" }}>{stage}</p>
        </StageContainer>
      ))}
    </FlexContainer>
  );
};
