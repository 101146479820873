import VerticalLine from "components/verticalLine";
import { contactSelector } from "data/selectors/contact";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { ClientSection } from "./clientSection";
import { FunctionSection } from "./functionSection";
import { SideSection } from "./sideSection";
import {
  FormContainer,
  LargeText,
  ScreenRoot,
  SideContainer,
  StyledSpan,
} from "./styles";

import {
  contractorAlertsSelector,
  serviceProviderBuyerListSelector,
  serviceProviderContractorsListSelector,
} from "data/selectors/serviceProvider";
import {
  getServiceProviderBuyersList,
  getServiceProviderContractorAlerts,
  getServiceProviderContractorsList,
} from "data/actions/serviceProvider";

function ContractorAgentDashboard() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [userData, setUserData] = useState("");
  const serviceProviderContractorData = useSelector(
    serviceProviderContractorsListSelector,
  );
  const contractorAlerts = useSelector(contractorAlertsSelector);

  const contactData = useSelector(contactSelector);
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (serviceProviderContractorData?.length < 1) {
      dispatch(getServiceProviderContractorsList());
    }
    if (contractorAlerts?.length < 1) {
      dispatch(getServiceProviderContractorAlerts());
    }
  }, []);

  return (
    <ScreenRoot isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <LargeText isMobile={isMobile}>
          Welcome Back,
          <StyledSpan>
            {contactData?.first_name
              ? contactData?.first_name
              : contactData?.last_name}
          </StyledSpan>
          !
        </LargeText>
        <ClientSection
          isMobile={isMobile}
          data={serviceProviderContractorData?.data}
          selectedTab="2"
          alertsData={contractorAlerts}
        />
        <FunctionSection
          isMobile={isMobile}
          userData={userData}
          selectedTab="2"
        />
      </FormContainer>
      {!isMobile && (
        <>
          <VerticalLine />
          <SideContainer isMobile={isMobile}>
            <SideSection isMobile={isMobile} selectedTab="2" />
          </SideContainer>
        </>
      )}
    </ScreenRoot>
  );
}

export default ContractorAgentDashboard;
