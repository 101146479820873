import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import {
  StyledActionButton,
  StyledActionButtonContainer,
  StyledToolbar,
} from "./styles";

import { PDFDocument, rgb } from "pdf-lib";

export const DocumentViewer = ({
  width = "700px",
  height = "900px",
  defaultRotation = 0,
  defaultPageNumber = 1,
  src,
  coordinates,
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [rotation, setRotation] = useState(defaultRotation);
  const [scale, setScale] = useState(1);

  const containerDivRef = useRef(null);
  const pageNumberInputRef = useRef(null);

  const [modifiedPdfBlob, setModifiedPdfBlob] = useState(null);

  useEffect(() => {
    if (!src) return;
    const modifyPdf = async () => {
      // Convert pdfsrc to an ArrayBuffer
      let arrayBuffer;
      if (src instanceof Blob) {
        arrayBuffer = await src.arrayBuffer();
      } else if (typeof src === "string") {
        // If pdfsrc is a URL, fetch it
        const response = await fetch(src);
        arrayBuffer = await response.arrayBuffer();
      } else {
        arrayBuffer = src;
      }

      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pages = pdfDoc.getPages();
      if (pages.length > 0) {
        const firstPage = pages[pageNumber - 1];
        const { width: pageWidth, height: pageHeight } = firstPage.getSize();

        console.log("coords: ", coordinates.x * parseInt(width));

        firstPage.drawRectangle({
          x: Math.round(coordinates.x * pageWidth),
          y: Math.round(coordinates.y * pageHeight),
          width: Math.round(coordinates.width * pageWidth),
          height: Math.round(coordinates.height * pageHeight),
          color: rgb(1, 1, 0),
          opacity: 0.25,
        });
      }

      const pdfBytes = await pdfDoc.save();
      setModifiedPdfBlob(new Blob([pdfBytes], { type: "application/pdf" }));
    };

    modifyPdf();
  }, [src, coordinates]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(numPages);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const inputValue = Number(event.currentTarget.value);
      if (inputValue >= 1 && inputValue <= pageCount) {
        setPageNumber(inputValue);
      }
    }
  };

  const handleGoToNextPage = () => {
    if (pageNumber < pageCount) {
      if (pageNumberInputRef.current) {
        pageNumberInputRef.current.value = "" + (pageNumber + 1);
      }
      setPageNumber(pageNumber + 1);
    }
  };

  const handleGoToPreviousPage = () => {
    if (pageNumber > 1) {
      if (pageNumberInputRef.current) {
        pageNumberInputRef.current.value = "" + (pageNumber - 1);
      }
      setPageNumber(pageNumber - 1);
    }
  };

  // Until the PDF is generated, show a loading message.
  if (!modifiedPdfBlob) {
    return <div>Loading PDF...</div>;
  }

  return (
    <div>
      {/* PDF Viewer Container */}
      <div
        ref={containerDivRef}
        style={{
          width,
          height,
          overflow: "auto",
          position: "relative",
          backgroundColor: "grey",
        }}
      >
        <Document
          file={modifiedPdfBlob}
          onLoadSuccess={onDocumentLoadSuccess}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          style={{ boxShadow: "0 2px 6px #0000000f" }}
        >
          <Page
            pageNumber={pageNumber}
            rotate={rotation}
            scale={scale}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            style={{ margin: "auto", borderRadius: "50%" }}
          />
        </Document>
      </div>

      {/* Toolbar Controls */}
      <StyledToolbar style={{ width, marginTop: "10px" }}>
        <StyledActionButtonContainer>
          <StyledActionButton
            title="Rotate"
            onClick={() => {
              const updatedRotation = (rotation + 90) % 360;
              const isLandscape =
                updatedRotation === 90 || updatedRotation === 270;
              if (isLandscape) {
                setScale((prevScale) => prevScale * 1.5);
              } else {
                setScale(1.0);
              }
              setRotation(updatedRotation);
            }}
          >
            <RotateIcon />
          </StyledActionButton>
        </StyledActionButtonContainer>

        <StyledActionButtonContainer>
          <StyledActionButton
            title="Previous page"
            onClick={handleGoToPreviousPage}
          >
            <BackChevronIcon />
          </StyledActionButton>
          <input
            ref={pageNumberInputRef}
            onKeyDown={handleKeyDown}
            defaultValue={pageNumber}
            style={{ width: "32px", height: "16px", margin: "0 5px" }}
          />
          /{pageCount}
          <StyledActionButton title="Next page" onClick={handleGoToNextPage}>
            <ForwardChevronIcon />
          </StyledActionButton>
        </StyledActionButtonContainer>

        <StyledActionButtonContainer>
          <StyledActionButton
            title="Zoom out"
            onClick={() => {
              setScale((prevScale) => Math.max(prevScale - 0.5, 0.5));
            }}
          >
            <ZoomOutIcon />
          </StyledActionButton>
          <StyledActionButton
            title="Zoom in"
            onClick={() => {
              setScale((prevScale) => prevScale + 0.5);
            }}
          >
            <ZoomInIcon />
          </StyledActionButton>
        </StyledActionButtonContainer>
      </StyledToolbar>
    </div>
  );
};

const ForwardChevronIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.7773 13.511L10.2227 8.35402L5.7773 3.19702"
      stroke="#1A051D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BackChevronIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2227 13.511L5.77734 8.35402L10.2227 3.19702"
      stroke="#1A051D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RotateIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.10425C8.56609 3.10425 7.16437 3.52945 5.97212 4.32609C4.77986 5.12273 3.85061 6.25503 3.30188 7.57979C2.75314 8.90456 2.60957 10.3623 2.88931 11.7687C3.16905 13.175 3.85955 14.4668 4.87348 15.4808C5.88741 16.4947 7.17924 17.1852 8.5856 17.4649C9.99196 17.7447 11.4497 17.6011 12.7745 17.0524C14.0992 16.5036 15.2315 15.5744 16.0282 14.3821C16.8248 13.1899 17.25 11.7882 17.25 10.3542C17.25 9.94003 17.5858 9.60425 18 9.60425C18.4142 9.60425 18.75 9.94003 18.75 10.3542C18.75 12.0848 18.2368 13.7766 17.2754 15.2155C16.3139 16.6544 14.9473 17.7759 13.3485 18.4382C11.7496 19.1005 9.9903 19.2737 8.29296 18.9361C6.59563 18.5985 5.03653 17.7651 3.81282 16.5414C2.58911 15.3177 1.75575 13.7586 1.41813 12.0613C1.08051 10.364 1.25379 8.60462 1.91606 7.00577C2.57832 5.40691 3.69983 4.04035 5.13876 3.07889C6.57769 2.11743 8.26941 1.60425 10 1.60425C12.4393 1.60425 14.7663 2.57204 16.5109 4.24912L16.5214 4.25947L17.25 4.988L17.2499 2.35426C17.2499 1.94005 17.5857 1.60426 17.9999 1.60425C18.4141 1.60424 18.7499 1.94002 18.7499 2.35423L18.75 6.79868C18.75 6.99759 18.671 7.18836 18.5303 7.32902C18.3897 7.46967 18.1989 7.54869 18 7.54869L13.5555 7.54869C13.1413 7.54869 12.8055 7.2129 12.8055 6.79869C12.8055 6.38448 13.1413 6.04869 13.5555 6.04869H16.1893L15.4661 5.32547C13.9935 3.91232 12.0383 3.10425 10 3.10425Z"
      fill="#1A051D"
    />
  </svg>
);

const ZoomOutIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_101_3772)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0525 15.9592C3.88137 15.9592 0.5 12.5779 0.5 8.40675C0.5 4.23562 3.88137 0.854248 8.0525 0.854248C12.2236 0.854248 15.605 4.23562 15.605 8.40675C15.605 12.5779 12.2236 15.9592 8.0525 15.9592ZM8.05255 14.535C11.4367 14.535 14.18 11.7917 14.18 8.40753C14.18 5.0234 11.4367 2.28003 8.05255 2.28003C4.66842 2.28003 1.92505 5.0234 1.92505 8.40753C1.92505 11.7917 4.66842 14.535 8.05255 14.535ZM18.8162 19.1702C19.0945 18.892 19.0945 18.4409 18.8162 18.1626L15.3962 14.7426C15.118 14.4644 14.6669 14.4644 14.3886 14.7426C14.1104 15.0209 14.1104 15.472 14.3886 15.7502L17.8086 19.1702C18.0869 19.4485 18.538 19.4485 18.8162 19.1702Z"
        fill="#1A051D"
      />
      <path
        d="M10 7.85425H6C5.73478 7.85425 5.48043 7.93327 5.29289 8.07392C5.10536 8.21457 5 8.40534 5 8.60425C5 8.80316 5.10536 8.99393 5.29289 9.13458C5.48043 9.27523 5.73478 9.35425 6 9.35425H10C10.2652 9.35425 10.5196 9.27523 10.7071 9.13458C10.8946 8.99393 11 8.80316 11 8.60425C11 8.40534 10.8946 8.21457 10.7071 8.07392C10.5196 7.93327 10.2652 7.85425 10 7.85425Z"
        fill="#1A051D"
      />
    </g>
    <defs>
      <clipPath id="clip0_101_3772">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.354248)"
        />
      </clipPath>
    </defs>
  </svg>
);

const ZoomInIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_101_3773)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0525 15.9592C3.88137 15.9592 0.5 12.5779 0.5 8.40675C0.5 4.23562 3.88137 0.854248 8.0525 0.854248C12.2236 0.854248 15.605 4.23562 15.605 8.40675C15.605 12.5779 12.2236 15.9592 8.0525 15.9592ZM8.05255 14.535C11.4367 14.535 14.18 11.7917 14.18 8.40753C14.18 5.0234 11.4367 2.28003 8.05255 2.28003C4.66842 2.28003 1.92505 5.0234 1.92505 8.40753C1.92505 11.7917 4.66842 14.535 8.05255 14.535ZM18.8162 19.1702C19.0945 18.892 19.0945 18.4409 18.8162 18.1626L15.3962 14.7426C15.118 14.4644 14.6669 14.4644 14.3886 14.7426C14.1104 15.0209 14.1104 15.472 14.3886 15.7502L17.8086 19.1702C18.0869 19.4485 18.538 19.4485 18.8162 19.1702Z"
        fill="#1A051D"
      />
      <path
        d="M10 7.85425H6C5.73478 7.85425 5.48043 7.93327 5.29289 8.07392C5.10536 8.21457 5 8.40534 5 8.60425C5 8.80316 5.10536 8.99393 5.29289 9.13458C5.48043 9.27523 5.73478 9.35425 6 9.35425H10C10.2652 9.35425 10.5196 9.27523 10.7071 9.13458C10.8946 8.99393 11 8.80316 11 8.60425C11 8.40534 10.8946 8.21457 10.7071 8.07392C10.5196 7.93327 10.2652 7.85425 10 7.85425Z"
        fill="#1A051D"
      />
      <path
        d="M8.75 10.6042L8.75 6.60425C8.75 6.33903 8.67098 6.08468 8.53033 5.89714C8.38968 5.70961 8.19891 5.60425 8 5.60425C7.80109 5.60425 7.61032 5.70961 7.46967 5.89714C7.32902 6.08468 7.25 6.33903 7.25 6.60425L7.25 10.6042C7.25 10.8695 7.32902 11.1238 7.46967 11.3114C7.61032 11.4989 7.80109 11.6042 8 11.6042C8.19891 11.6042 8.38968 11.4989 8.53033 11.3114C8.67098 11.1238 8.75 10.8695 8.75 10.6042Z"
        fill="#1A051D"
      />
    </g>
    <defs>
      <clipPath id="clip0_101_3773">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.354248)"
        />
      </clipPath>
    </defs>
  </svg>
);
